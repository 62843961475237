<template>
  <div>
    <preloader />
    <div id="layout-wrapper" v-if="user">
      <NavBar :name="user.name" />

      <SideBar
        :is-condensed="isMenuCondensed"
        :type="leftSidebarType"
        :width="layoutWidth"
      />

      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid mt-5 pt-5">
            <slot name="header" />
            <router-view />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import router from "@/router";
import { layoutComputed } from "@/store/helpers";

import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import Footer from "@/components/footer";

/**
 * Vertical layout
 */
export default {
  name: "admin-layout",
  components: { NavBar, SideBar, Footer },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
    ...mapState("auth", ["user"])
  },
  mounted() {
    this.$store.dispatch("promotions/getRequestedCount");
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-layout", "vertical");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.$store.commit("layout/TOGGLE_MENU");
    }
  }
};
</script>
